import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Login2 from "pages/Authentication/Login2"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Support
import SupportIndex from "pages/Support"
import AddReply from "pages/Support/AddReply"
import TaskIndex from "pages/Task"
import { configure } from "configure"

const authProtectedRoutes = [
  { path: `${configure.appUrl}dashboard`, component: <Dashboard /> },

  // Support
  { path: `${configure.appUrl}support`, component: <SupportIndex /> },
  { path: `${configure.appUrl}add-reply/:id`, component: <AddReply /> },

  // tasks
  // { path: "/mars-client/task", component: <TaskIndex /> },

  // //profile
  { path: `${configure.appUrl}profile`, component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: `${configure.appUrl}`,
    exact: true,
    component: < Navigate to={`${configure.appUrl}dashboard`} />,
  },

]

const publicRoutes = [
  { path: `${configure.appUrl}logout`, component: <Logout /> },
  { path: `${configure.appUrl}login2`, component: <Login /> },
  { path: `${configure.appUrl}login`, component: <Login2 /> },
  { path: `${configure.appUrl}forgot-password`, component: <ForgetPwd /> },
  { path: `${configure.appUrl}register`, component: <Register /> },
]

export { authProtectedRoutes, publicRoutes }