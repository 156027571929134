import React, { useState, useEffect } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import Dropzone from "react-dropzone"
import swal from "sweetalert"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Container, Row, Modal, Form, Col, Card, CardBody } from "reactstrap"

// Light Box for image
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import axios from "axios"

// editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"
// Assets
import "../../assets/css/Support.css"
import userImage from "../../assets/images/profile-img.png"
import img1 from "../../assets/images/users/user-img.png"
import Loader from "pages/Loader"
import { configure } from "configure"

// import image from '../../assets/images/megamenu-img.png'

const AddReply = () => {
  const [modal_large, setmodal_large] = useState(false)

  const [selectedFiles1, setselectedFiles1] = useState([])
  const [selectedFiles2, setselectedFiles2] = useState([])

  function handleAcceptedFiles1(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles1(files)
  }

  function handleAcceptedFiles2(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles2(files)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  document.title = "Add Reply | Zithas Client"

  const navigate = useNavigate()

  // Modal
  function tog_large() {
    setmodal_large(!modal_large)
    removeBodyCss()
  }

  const openNav = () => {
    document.getElementById("mySidenav").classList.add("width-reply")
  }

  const onCloseNav = () => {
    document.getElementById("mySidenav").classList.remove("width-reply")
  }

  const [description, setDescription] = useState("")
  const [particular_data, setParticularData] = useState([])
  const [ticket_files, setAllFiles] = useState([])
  const [isLoading, setLoading] = useState(false)
  const params = useParams()

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    setLoading(true)
    const formData = new FormData()
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/client_ticket_details/${params.id}`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setParticularData([response.data.data[0]])
        setAllFiles(response.data.data.files)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  // Displaying Data

  let TicketId
  let TicketSubject
  let ProjectName
  let TicketPriority
  let TicketStatus
  let TicketDescription
  const TicketData = particular_data.map(item => {
    TicketId = item.id
    TicketSubject = item.subject
    ProjectName = item.project_name
    TicketPriority = item.priority
    if (item.status == "Closed") {
      TicketStatus = item.status
    } else {
      TicketStatus = "Open"
    }

    // if (item.description != null) {
    TicketDescription = item.description
    // }
  })

  const nameArray = ticket_files.map(item => item.file_name)
  const newArray = nameArray.map(
    item => "https://mars.ztpl.net/application/public" + item
  )

  // Add Reply to Particular Ticket

  const [status, setStatus] = useState("")

  const onAddReply = async e => {
    e.preventDefault()
    swal({
      title: "Are you sure?",
      text: "You want to Add Reply !",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async willDelete => {
      if (willDelete) {
        setLoading(true)
        const formData = new FormData()
        formData.append("contact_id", localStorage.getItem("userid"))
        formData.append("support_id", params.id)
        formData.append("status", status)
        formData.append("description", description)
        for (let i = 0; i < selectedFiles1.length; i++) {
          formData.append("files[]", selectedFiles1[i])
        }
        try {
          const response = await axios({
            method: "post",
            url: `${configure.apiUrl}client_add_reply`,
            data: formData,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }).then(response => {
            swal({
              icon: "success",
              text: "Replied Successfully",
            }).then(data => {
              document.getElementById("mySidenav").style.width = "0"
              setDescription("")
              setStatus("")
              setselectedFiles1([])
              setAllReply(response.data.data)
            })
            setLoading(false)
          })
        } catch (err) {
          console.log(err)
          setLoading(false)
        }
      }
    })
  }

  // Displaying Reply

  const [reply_data, setAllReply] = useState([])
  const [reply_files, setReplyArrayFiles] = useState([])
  useEffect(() => {
    useFectch()
  }, [])

  const useFectch = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("support_id", params.id)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}client_reply_details`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        // console.log(response)
        setAllReply(response.data.data)
        setLoading(false)
        const datas = response.data.data.map(item => {
          setReplyArrayFiles(JSON.parse(item.files))
        })
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  // console.log(reply_files)

  const [reply_description, setReplyDescription] = useState("")
  const [reply_status, setReplyStatus] = useState("")
  const [reply_id, setReplyId] = useState("")

  const onEditReply = async e => {
    e.preventDefault()
    swal({
      title: "Are you sure?",
      text: "You want to Edit this Reply !",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async willDelete => {
      if (willDelete) {
        setLoading(true)
        const formData = new FormData()
        formData.append("reply_id", reply_id)
        formData.append("support_id", params.id)
        formData.append("status", reply_status)
        formData.append("description", reply_description)
        try {
          const response = await axios({
            method: "post",
            url: `${configure.apiUrl}client_reply_edit`,
            data: formData,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }).then(response => {
            swal({
              icon: "success",
              text: "Reply Edited Successfully",
            }).then(data => {
              setmodal_large(false)
            })
            setAllReply(response.data.data)
            setLoading(false)
          })
        } catch (err) {
          console.log(err)
          setLoading(false)
        }
      }
    })
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Dashboards"} breadcrumbItem={"Add Reply"} />
          {/* Light Box */}

          <div className="p-4 bg-white shadow rounded">
            {/* Top Section */}
            <div className="d-flex justify-content-between display-flex-column">
              <div className="">
                <h3>
                  #{TicketId} - {TicketSubject}
                </h3>
                <h5>
                  <a href="#">{ProjectName}</a>{" "}
                </h5>
                {/* <h5>
                <a href="#">Status</a>
                </h5> */}
              </div>
              <div>
                <button className="btn btn-purple me-2" onClick={openNav}>
                  <i className="fas fa-paper-plane  me-2 "></i> Reply
                </button>
                <button className="btn btn-red" onClick={() => navigate(-1)}>
                  <i className="dripicons-cross me-1 icon-center"></i> cancel
                </button>
              </div>
              <div className="mt-20 bd-web-none">
                <span>
                  {" "}
                  <span className=" text-black">Priority</span> :{" "}
                  {TicketPriority}{" "}
                </span>
                <span className="ml-20 text-black">Status</span>{" "}
                <span> : {TicketStatus}</span>
              </div>
            </div>

            <Row>
              <div className="col-12 mt-3 mb-15">
                <div className="display-flex justify-space-between mb-10">
                  <h5>Description</h5>
                  <div className="bd-status">
                    <span>
                      {" "}
                      <span className="ml-20 text-black">Priority</span> :{" "}
                      {TicketPriority}{" "}
                    </span>
                    <span className="ml-20 text-black">Status</span>{" "}
                    <span> : {TicketStatus}</span>
                  </div>
                </div>

                <div className="bd-description-sect ">
                  <div
                    className="Features"
                    dangerouslySetInnerHTML={{ __html: TicketDescription }}
                  />
                </div>
              </div>
              <div className="col-md-4 mt-3">
                <h5>Attachment</h5>
                <div className="d-flex mt-10">
                  {ticket_files.length == 0 ? (
                    <div>
                      <div> No Files is Attached</div>
                    </div>
                  ) : (
                    <div className="d-flex">
                      {ticket_files.map((item, index) => {
                        console.log(ticket_files.length)
                        return (
                          <div className="view-ticket-detail " key={index}>
                            <Zoom>
                              <img src={item.file_name} alt="" />
                            </Zoom>
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>
              </div>
            </Row>

            <div id="mySidenav" className="sidenav">
              <div className="p-4 bg-white shadow rounded mt-10 mb-10">
                <form onSubmit={onAddReply}>
                  <div className="d-flex justify-content-between display-flex-column">
                    <div className="">
                      <h3>
                        #{TicketId} - {TicketSubject}
                      </h3>
                      <h5>
                        <a href="#">{ProjectName}</a>{" "}
                      </h5>
                    </div>
                    <div>
                      <button className="btn btn-purple me-2">
                        <i className="fas fa-paper-plane  me-2 "></i> Reply
                      </button>
                      <a className="btn btn-red" onClick={onCloseNav}>
                        <i className="dripicons-cross me-1 icon-center"></i>{" "}
                        cancel
                      </a>
                    </div>
                  </div>
                  <Row>
                    <div className="col-12 mt-3">
                      <div className="display-flex justify-content-between">
                        <h5>Description</h5>
                        <div className="bd-status">
                          <span>
                            {" "}
                            <span className="ml-20 text-black">
                              Priority
                            </span> : {TicketPriority}{" "}
                          </span>
                          <span className="ml-20 text-black">Status</span>{" "}
                          <span> : {TicketStatus}</span>
                        </div>
                      </div>
                      <CKEditor
                        editor={ClassicEditor}
                        onReady={editor => {
                          // You can store the "editor" and use when it is needed.
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData()
                          setDescription(data)
                        }}
                      />
                    </div>

                    <div className="col-md-4 mt-3">
                      <label htmlFor="">Status</label>
                      <select
                        className="form-control"
                        onChange={e => setStatus(e.target.value)}
                        required
                      >
                        <option value="">Select Status</option>
                        <option value="Open">Open</option>
                        <option value="Closed">Closed</option>
                      </select>
                    </div>
                    <div className="col-md-4 mt-3">
                      <label>Attachment</label>
                      <Dropzone
                        onDrop={acceptedFiles => {
                          handleAcceptedFiles1(acceptedFiles)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            className="dz-message needsclick p-0"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="d-flex px-2 custom-file-upload align-items-center">
                              <div className="">
                                <i
                                  className="display-5 text-muted bx bxs-cloud-upload"
                                  style={{ fontSize: "35px" }}
                                />
                              </div>
                              <h5
                                className="mb-0 ms-2"
                                style={{ fontSize: "14px" }}
                              >
                                Upload Files
                              </h5>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                    <div
                      className={`col-md-4 mt-20 dropzone-previews ${
                        selectedFiles1.length > 0 ? "d-block" : "d-none"
                      }`}
                      id="file-previews"
                    >
                      <label className="mb-1">Uploaded Files</label>
                      <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                        <div className="p-1">
                          <Row className="align-items-center justify-content-evenly">
                            {selectedFiles1.map((f, i) => {
                              return (
                                <Col key={i} className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                              )
                            })}
                          </Row>
                        </div>
                      </Card>
                    </div>
                  </Row>
                </form>
              </div>
            </div>
          </div>

          {reply_data.length != 0 && (
            <Row className="mt-3">
              <Col lg="12">
                <Card>
                  <CardBody className="">
                    <div>
                      <ul className="verti-timeline list-unstyled notes-box">
                        {/* Activities */}
                        {reply_data.map((note, key) => {
                          const arrayOfObjects = JSON.parse(note.files).map(
                            (item, index) => {
                              return { id: index + 1, image: item }
                            }
                          )

                          const onDeleteReply = async e => {
                            e.preventDefault()
                            swal({
                              title: "Are you sure?",
                              text: "You want to Delete this Reply !",
                              icon: "warning",
                              buttons: true,
                              dangerMode: true,
                            }).then(async willDelete => {
                              if (willDelete) {
                                setLoading(true)
                                const formData = new FormData()
                                formData.append("reply_id", note.id)
                                formData.append("support_id", params.id)
                                try {
                                  const response = await axios({
                                    method: "post",
                                    url: `${configure.apiUrl}client_reply_delete`,
                                    data: formData,
                                    headers: {
                                      Accept: "application/json",
                                      "Content-Type": "multipart/form-data",
                                    },
                                  }).then(response => {
                                    swal({
                                      icon: "success",
                                      text: "Reply Deleted Successfully",
                                    }).then(data => {
                                      setmodal_large(false)
                                    })
                                    setAllReply(response.data.data)
                                    setLoading(false)
                                  })
                                } catch (err) {
                                  console.log(err)
                                  setLoading(false)
                                }
                              }
                            })
                          }
                          let Edited
                          if (note.created_at != note.updated_at) {
                            Edited = (
                              <span className="font-size-12 badge-soft-secondary badge ">
                                Edited
                              </span>
                            )
                          }

                          let EditDelete
                          if (note.type == "Client") {
                            EditDelete = (
                              <>
                                <div className="pe-3 action-side">
                                  <button
                                    title="edit"
                                    onClick={() => {
                                      setReplyId(note.id)
                                      setReplyStatus(note.status)
                                      setReplyDescription(note.reply)
                                      tog_large()
                                    }}
                                    className="btn btn-sm btn-outline-secondary me-2"
                                  >
                                    <i className="fa fa-edit" title="Edit"></i>
                                  </button>
                                  <button
                                    title="delete"
                                    className="btn btn-sm btn-danger"
                                    onClick={onDeleteReply}
                                  >
                                    <i
                                      className="fa fa-trash"
                                      title="Delete"
                                    ></i>
                                  </button>
                                </div>

                                <div
                                  className="dropdown-edit-delete v bd-web-none"
                                  style={{ width: "10%" }}
                                >
                                  <div
                                    className=""
                                    style={{
                                      padding: "4px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <i className="fas fa-ellipsis-v "></i>
                                  </div>
                                  <div className="dropdown-content">
                                    <a
                                      onClick={() => {
                                        setReplyId(note.id)
                                        setReplyStatus(note.status)
                                        setReplyDescription(note.reply)
                                        tog_large()
                                      }}
                                    >
                                      Edit
                                    </a>
                                    <a onClick={onDeleteReply}>Delete</a>
                                  </div>
                                </div>
                              </>
                            )
                          }
                          return (
                            <li
                              key={key}
                              className="event-list position-relative reply-padding-0"
                            >
                              <div className="event-timeline-dot">
                                <img
                                  src={img1}
                                  className="userImg me-3 mobile-icon-view"
                                  alt=""
                                />
                              </div>
                              <div className="d-flex ">
                                <div className="flex-grow-1 ml-20 reply-m4">
                                  <div className="note py-2 rounded ps-4 pt-20">
                                    <div className="d-flex justify-content-between">
                                      <div className="bd-descriptive-side">
                                        <p className="text-muted d-flex align-item-center display-flex-column">
                                          <h6 className="d-inline me-3 mb-0">
                                            By {note.type}
                                          </h6>{" "}
                                          {note.created_at}
                                          <span className="pl-20">
                                            {Edited}
                                          </span>
                                        </p>
                                        <div className="d-flex">
                                          {arrayOfObjects.length == 0 ? (
                                            <div>No Files is Attached</div>
                                          ) : (
                                            <>
                                              {arrayOfObjects.map(
                                                (item, index) => {
                                                  return (
                                                    <div
                                                      className="add-reply-images"
                                                      key={index}
                                                    >
                                                      <Zoom>
                                                        <img
                                                          src={item.image}
                                                          alt=""
                                                        />
                                                      </Zoom>
                                                    </div>
                                                  )
                                                }
                                              )}
                                            </>
                                          )}
                                        </div>
                                        <div className="mt-3">
                                          <div
                                            className="Features"
                                            dangerouslySetInnerHTML={{
                                              __html: note.reply,
                                            }}
                                          />
                                        </div>
                                      </div>
                                      {EditDelete}
                                    </div>
                                    <i className="bi bi-triangle-fill note-icon"></i>
                                  </div>
                                </div>
                              </div>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {/* Add Ticket Modal */}

          <Modal
            size="lg"
            isOpen={modal_large}
            toggle={() => {
              tog_large()
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myLargeModalLabel">
                Edit Ticket
              </h5>
              <button
                onClick={() => {
                  setmodal_large(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form onSubmit={onEditReply}>
                <Row>
                  <div className="col-12 mb-3">
                    <label htmlFor="">Description</label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={reply_description}
                      onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        setReplyDescription(data)
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="">Status</label>
                    <select
                      className="form-control"
                      onChange={e => setReplyStatus(e.target.value)}
                      required
                    >
                      <option value={reply_status}>{reply_status}</option>
                      <option value="Open">Open</option>
                      <option value="Closed">Closed</option>
                    </select>
                  </div>
                  <div className="border-bottom pb-2"></div>
                  <div className="mt-3 text-end">
                    <button className="btn btn-purple mr-20">Submit</button>
                    <a
                      className="btn btn-red"
                      onClick={() => {
                        setmodal_large(false)
                      }}
                    >
                      Cancel
                    </a>
                  </div>
                </Row>
              </Form>
            </div>
          </Modal>
          {/* ------------ */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddReply
