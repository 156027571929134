import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import withRouter from "components/Common/withRouter";

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";

import { Link } from "react-router-dom";

import zithasLogo from "../../assets/images/zithas-logo.png";
import zithasLogoSm from "../../assets/images/zithas-sm-logo.png";
import { configure } from "configure";

const Sidebar = props => {

  return (
    <React.Fragment>
      <div className="vertical-menu bg-sidebar">
        <div className="navbar-brand-box text-start bg-header">
          <Link to={`${configure.appUrl}`} className="logo">
            <span className="logo-lg">
              <img src={zithasLogo} alt="" height="45" />
            </span>
          </Link>
          <Link to={`${configure.appUrl}`} className="logo">
            <span className="logo-sm">
              <img src={zithasLogoSm} alt="" height="22" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)));
