import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// users
import user1 from "../../../assets/images/users/user-icon-png.jpeg"
import axios from "axios"
import { configure } from "configure"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [username, setusername] = useState("Admin")

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.username)
      }
    }
  }, [props.success])

  const Name = localStorage.getItem("name").split(" ")
  const navigate = useNavigate()

  const [profile, setProfile] = useState("")

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    const formData = new FormData()
    formData.append("customer_id", localStorage.getItem("customerId"))
    formData.append("contact_id", localStorage.getItem("userid"))
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}client_project_list`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        console.log(response)
        setProfile(response.data.data.profile_data.profile)
      })
    } catch (err) {
      console.log(err)
    }
  }

  let Profilepic
  if (profile != null) {
    Profilepic = (
      <img
        className="rounded-circle header-profile-user"
        src={profile}
        style={{ background: "none", height: "38px", width: "38px" }}
      />
    )
  } else {
    Profilepic = (
      <i
        className="bx bxs-user bd-dash-users"
        style={{ fontSize: "17px", borderRadius: "50%", padding: "6px" }}
      ></i>
    )
  }
  const onLogout = () => {
    localStorage.clear()
    navigate(`${configure.appUrl}login`)
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block "
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          {/* <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
            style={{background:"none", height:"38px", width:"38px"}}
          /> */}
          {Profilepic}
          {/* <i className="bx bxs-user bd-dash-users" style={{fontSize:"17px", borderRadius:"50%", padding:"6px"}}></i> */}
          <span className="d-none d-xl-inline-block text-white ms-2 me-1">
            {Name.slice(0, 1)}
          </span>
          <i className="mdi mdi-chevron-down text-white d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end ">
          <DropdownItem>
            <Link to={`${configure.appUrl}profile`}>
              <i className="bx bx-user font-size-16 align-middle me-1" />
              {props.t("Profile")}{" "}
            </Link>
          </DropdownItem>
          <div className="dropdown-divider" />
          <a
            className="dropdown-item"
            onClick={() => {
              onLogout()
            }}
          >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </a>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
