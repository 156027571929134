import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"
import withRouter from "components/Common/withRouter"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/avatar-1.jpg"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"
import axios from "axios"
import swal from "sweetalert"
import Loader from "pages/Loader"
import { configure } from "configure"

const UserProfile = () => {
  //meta title
  document.title = "Profile"

  const dispatch = useDispatch()

  const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [idx, setidx] = useState(1)

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }))

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setname(obj.displayName)
        setemail(obj.email)
        setidx(obj.uid)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setname(obj.username)
        setemail(obj.email)
        setidx(obj.uid)
      }
      setTimeout(() => {
        dispatch(resetProfileFlag())
      }, 3000)
    }
  }, [dispatch, success])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: name || "",
      idx: idx || "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your UserName"),
    }),
    onSubmit: values => {
      dispatch(editProfile(values))
    },
  })

  const [profile, setProfile] = useState("")

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    const formData = new FormData()
    formData.append("customer_id", localStorage.getItem("customerId"))
    formData.append("contact_id", localStorage.getItem("userid"))
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}client_project_list`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setProfile(response.data.data.profile_data.profile)
      })
    } catch (err) {
      console.log(err)
    }
  }

  let Profilepic
  if (profile != null) {
    Profilepic = (
      <img
        src={profile}
        alt=""
        className="avatar-md rounded-circle img-thumbnail"
      />
    )
  } else {
    Profilepic = (
      <div>
        <i className="bx bxs-user bd-dash-users"></i>
      </div>
    )
  }

  // Change password Funcionality

  const [password, setPassword] = useState("")
  const [C_password, setConfirmPassword] = useState("")
  const [isLoading, setLoading] = useState(false)

  const onChangePassword = async e => {
    e.preventDefault()
    if (C_password === password) {
      setLoading(true)
      const formData = new FormData()
      formData.append("contact_id", localStorage.getItem("userid"))
      formData.append("password", password)
      try {
        const response = await axios({
          method: "post",
          url: `${configure.apiUrl}change_password`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          swal({
            icon: "success",
            text: "Password Changed Successfully",
          }).then(() => {
            window.location.reload()
          })
          setLoading(false)
        })
      } catch (err) {
        swal({
          icon: "error",
          text: "Something Went Wrong",
        })
        console.log(err)
        setLoading(false)
      }
    } else {
      swal({
        icon: "error",
        text: "Please Enter Correct password",
      })
    }
  }

  const [passwordType, setPasswordType] = useState("password")

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return
    }
    setPasswordType("password")
  }
  return (
    <React.Fragment>
      {isLoading && (<Loader/>)}
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Dashboard" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      {/* <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      /> */}
                      {/* <div>
                        <i className="bx bxs-user bd-dash-users"></i>
                      </div> */}
                      {Profilepic}
                    </div>
                    <div className="flex-grow-1 align-self-center ml-15">
                      <div className="text-muted">
                        <h5>{localStorage.getItem("name")}</h5>
                        <p className="mb-1">
                          {localStorage.getItem("position")}
                        </p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Change Password</h4>

          <Card>
            <CardBody>
              <Form className="form-horizontal" onSubmit={onChangePassword}>
                <Row>
                  <div className="form-group col-md-4 mb-20">
                    <Label className="form-label">Enter New Password</Label>
                    <div className="position-relative">
                      <Input
                        name="username"
                        // value={name}
                        className="form-control"
                        placeholder="Enter New Password"
                        type={passwordType}
                        onChange={e => setPassword(e.target.value)}
                      />
                      <a className="bd-pass-icon" onClick={togglePassword}>
                        {passwordType === "password" ? (
                          <i className="fas fa-eye-slash "></i>
                        ) : (
                          <i className="fas fa-eye "></i>
                        )}
                      </a>
                    </div>
                  </div>
                  <div className="form-group col-md-4">
                    <Label className="form-label">Enter Confirm Password</Label>
                    <Input
                      name="username"
                      // value={name}
                      className="form-control"
                      placeholder="Enter  Confirm Password"
                      type="password"
                      onChange={e => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </Row>
                <div className=" mt-4">
                  <Button
                    type="submit"
                    className="btn btn-purple"
                    style={{ border: "none" }}
                  >
                    Update Password
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
