import React, { useState, useEffect } from "react"
import { Col, Container, Row } from "reactstrap"
import { Card } from "reactstrap"
import { CardBody } from "reactstrap"
import LatestTranaction from "./LatestTranaction"
import axios from "axios"
//redux
import { getChartsData as onGetChartsData } from "../../store/actions"
import { useSelector, useDispatch } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"
import Section from "./Section"
import Loader from "pages/Loader"
import { configure } from "configure"

const Dashboard = props => {
  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
  }))

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    dispatch(onGetChartsData(pType))
  }

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch])

  //meta title
  document.title = "Dashboard | Zithas Client"

  const [isLoading, setLoading] = useState(true)
  const [total_project, setTotalProject] = useState("")
  const [total_ticket, setTotalTicket] = useState("")
  const [closed_ticket, setClosedTicket] = useState("")
  const [open_ticket, setOpenTicket] = useState("")
  const [project_id, setProjectId] = useState("")
  const [all_project, setAllProject] = useState([])
  const [profile, setProfile] = useState("")
  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    const formData = new FormData()
    formData.append("customer_id", localStorage.getItem("customerId"))
    formData.append("contact_id", localStorage.getItem("userid"))
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}client_project_list`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setTotalProject(response.data.data.project_data.length)
        setAllProject(response.data.data.project_data)
        setTotalTicket(response.data.data.total)
        setClosedTicket(response.data.data.closed)
        setOpenTicket(response.data.data.open)
        setLoading(false)
        setProfile(response.data.data.profile_data.profile)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl="12">
              <Section profile={profile} />
            </Col>
            <Col xl="12">
              <Row>
                <Col md="3">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Total Projects</p>
                          <h4 className="mb-0">{total_project}</h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className="bx  bx-purchase-tag-alt font-size-24"></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Total Tickets</p>
                          <h4 className="mb-0">{total_ticket}</h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className="bx bx-copy-alt font-size-24"></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                  <Card className="mini-stats-wid">
                    <CardBody
                      className=""
                      style={{ backgroundColor: "rgba(244, 106, 106, 0.18)" }}
                    >
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p
                            className=" fw-medium"
                            style={{ color: "#f46a6a" }}
                          >
                            Open Tickets
                          </p>
                          <h4 className="mb-0" style={{ color: "#f46a6a" }}>
                            {open_ticket}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className="bx bx-archive-in font-size-24"></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                  <Card className="mini-stats-wid">
                    <CardBody
                      style={{ backgroundColor: "rgba(52,195,143,.18)" }}
                    >
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="fw-medium" style={{ color: "#34c38f" }}>
                            Closed Tickets
                          </p>
                          <h4 className="mb-0" style={{ color: "#34c38f" }}>
                            {closed_ticket}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className="bx bx-purchase-tag-alt font-size-24"></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <LatestTranaction all_project={all_project} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Dashboard)
