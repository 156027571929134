import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import withRouter from "../../components/Common/withRouter";
import { isEmpty } from "lodash";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
} from "reactstrap";
import axios from "axios";
import { getOrders as onGetOrders } from "../../store/actions"

import EcommerceOrdersModal from "../Ecommerce/EcommerceOrders/EcommerceOrdersModal";

import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
} from "./LatestTranactionCol";

import TableContainer from "../../components/Common/TableContainer";

//redux
import { useSelector, useDispatch } from "react-redux";
import Loader from "pages/Loader";
import { configure } from "configure";

const LatestTranaction = ({ all_project }) => {

  const [modal1, setModal1] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const toggleViewModal = () => setModal1(!modal1);


  const [isLoading, setLoading] = useState(false)
  const [data, setDatas] = useState([])
  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("contact_id", localStorage.getItem("userid"))
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}client_ticket_data`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        if (response.data.success == false) {
          setDatas([])
        } else {
          const newArray = response.data.data.map((item) =>
          (
            { ...item, created_at: item.created_at.slice(0, 10), updated_at: item.updated_at.slice(0, 10) }
          ));
          const filteredArray = newArray.filter((item) => item.status !== "5");
          setDatas(filteredArray.slice(0, 10))
        }
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }



  const navigate = useNavigate()


  // const data = [
  //   {
  //     id: 1,
  //     subject: 'test 1',
  //     project: 'Test Project 99',
  //     status: 'Not answered',
  //     priority: 'High',
  //     lastReply: '22-03-2023',
  //     created: '22-03-2023'
  //   },
  //   {
  //     id: 2,
  //     subject: 'test 2',
  //     project: 'Test Project 99',
  //     status: 'Not answered',
  //     priority: 'High',
  //     lastReply: '22-03-2023',
  //     created: '22-03-2023'
  //   },
  //   {
  //     id: 3,
  //     subject: 'test 3',
  //     project: 'Test Project 99',
  //     status: 'Not answered',
  //     priority: 'High',
  //     lastReply: '22-03-2023',
  //     created: '22-03-2023'
  //   },
  //   {
  //     id: 4,
  //     subject: 'test 4',
  //     project: 'Test Project 99',
  //     status: 'Not answered',
  //     priority: 'High',
  //     lastReply: '22-03-2023',
  //     created: '22-03-2023'
  //   },
  //   {
  //     id: 5,
  //     subject: 'test 5',
  //     project: 'Test Project 99',
  //     status: 'Not answered',
  //     priority: 'High',
  //     lastReply: '22-03-2023',
  //     created: '22-03-2023'
  //   }
  // ]

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: true,
        // disableFilters: true,
        className: "width-id ",
      },
      {
        Header: "Subject",
        accessor: "subject",
        filterable: true,
        // disableFilters: true,
      },
      {
        Header: "Project",
        accessor: "project_name",
        filterable: true,
        // disableFilters: true,
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        // disableFilters: true,
        className: "status-width",
        Cell: cellProps => {
          const onStatusChange = async e => {
            swal({
              title: "Are you sure?",
              text: "You want to Change the Status !",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async willDelete => {
              if (willDelete) {
                setLoading(true)
                const formData = new FormData()
                formData.append("contact_id", localStorage.getItem("userid"))
                formData.append("support_id", cellProps.row.values.id)
                formData.append("status", e.target.value)
                try {
                  const response = await axios({
                    method: "post",
                    url: `${configure.apiUrl}client_ticket_status_change`,
                    data: formData,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  }).then(response => {
                    swal({
                      icon: "success",
                      text: "Status Changed Successfully",
                    }).then(data => {
                      window.location.reload()
                    })

                    setLoading(false)
                  })
                } catch (err) {
                  console.log(err)
                  setLoading(false)
                }
              }
            })
          }

          let SelectOption
          if (cellProps.row.values.status == "Closed") {
            SelectOption = (
              <span className="font-size-11 badge-soft-success badge bg-secondary">
                {cellProps.row.values.status}
              </span>
            )
          } else {
            SelectOption = (
              <select
                className="font-size-11 badge-soft-danger badge bg-secondary arrow_none bd-responsivewidth"
                style={{ border: "none" }}
                onChange={onStatusChange}
              >
                <option>Open</option>
                <option value="Closed">Closed</option>
              </select>
            )
          }

          return <div className="arrow_none">{SelectOption}</div>
        },
      },
      {
        Header: "Priority",
        accessor: "priority",
        filterable: true,
        // disableFilters: true,
        className: "priority-width",
      },
      {
        Header: "Last Reply",
        accessor: "updated_at",
        filterable: true,
        // disableFilters: true,
        className: "date_width "
      },
      {
        Header: "Created",
        accessor: "created_at",
        filterable: true,
        // disableFilters: true,
        className: "date_width "
      },
      {
        Header: "Action",
        disableFilters: true,
        accessor: "view",
        className: "width-action ",
        Cell: cellProps => {
          const clickCallback = id => {
            navigate(`/add-reply/${cellProps.row.values.id}`)
          }

          const onDeleteTicket = (e) => {
            swal({
              title: "Are you sure?",
              text: "You want to Delete this Ticket !",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async willDelete => {
              if (willDelete) {
                setLoading(true)
                const formData = new FormData()
                formData.append("contact_id", localStorage.getItem("userid"))
                formData.append("support_id", cellProps.row.values.id)
                try {
                  const response = await axios({
                    method: "post",
                    url: `${configure.apiUrl}client_ticket_delete`,
                    data: formData,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  }).then(response => {
                    swal({
                      icon: "success",
                      text: "Status Changed Successfully",
                    })

                    const newArray = response.data.data.map((item) =>
                    (
                      { ...item, created_at: item.created_at.slice(0, 10), updated_at: item.updated_at.slice(0, 10) }
                    ));
                    const filteredArray = newArray.filter((item) => item.status !== "Closed");
                    setDatas(filteredArray.slice(0, 10))
                    setLoading(false)
                  })
                } catch (err) {
                  console.log(err)
                  setLoading(false)
                }
              }
            })
          }

          return (
            <div>
              <a onClick={clickCallback} title='Add Reply' className='btn action-btn btn-sm me-1 btn-outline-success no-margin'>
                <i className="fas fa-ticket-alt"></i>
              </a>
              <a className='btn btn-sm action-btn btn-danger' onClick={onDeleteTicket}>
                <i className="fas fa-trash-alt"></i>
              </a>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <Card>
        <CardBody>

          <TableContainer
            columns={columns}
            data={data}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            all_project={all_project}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

LatestTranaction.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(LatestTranaction);
